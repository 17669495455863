import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Animation from './animations';

interface IErrorModal {
  errorModalIsOpen: boolean;
  setErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  errorMessageBody: string;
  errorMessageHeading: string;
}

const ErrorModal: React.FC<IErrorModal> = ({
  errorModalIsOpen,
  setErrorModalOpen,
  errorMessageBody,
  errorMessageHeading,
  t
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  function handleCloseModal() {
    setErrorModalOpen(false);
  }
  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current?.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (errorModalIsOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [errorModalIsOpen]);

  return (
    <Modal
      isOpen={errorModalIsOpen}
      overlayClassName="Overlay"
      className="react-modal-success fixed flex justify-center items-center z-50 w-full h-full top-0 left-0 bg-[#354452]/60 outline-none"
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      overlayElement={(props: any, contentElement: any) => (
        <div {...props} onClick={handleOutsideClick}>
          {contentElement}
        </div>
      )}
    >
      <Animation className="flex justify-center items-center">
        <div className="w-[420px] max-w-[90%] bg-white" ref={modalRef}>
          <div className="relative uk-modal-header rounded-none bg-[#D9F2FF]">
            <button
              className="absolute right-4 p-2"
              type="button"
              onClick={handleCloseModal}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="1"
                  y1="1"
                  x2="13"
                  y2="13"
                ></line>
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="13"
                  y1="1"
                  x2="1"
                  y2="13"
                ></line>
              </svg>
            </button>
            <h2 className="uk-modal-title">{errorMessageHeading}</h2>
          </div>
          <div
            className="uk-modal-body react-modalcontent rounded-none"
            uk-overflow-auto="true"
          >
            <div className="sweet-alert -mt-8">
              <div className="icon error animateErrorIcon">
                <span className="x-mark animateXMark">
                  <span className="line left"></span>
                  <span className="line right"></span>
                </span>
              </div>
            </div>
            <div className="my-4">{errorMessageBody}</div>
          </div>
          <div className="uk-modal-body pt-0 w-full flex justify-end">
            <button
              className="btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase m-0"
              onClick={handleCloseModal}
            >
              {t('success_close')}
            </button>
          </div>
        </div>
      </Animation>
    </Modal>
  );
};

export default ErrorModal;
