import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Animation from './animations';

interface ISmallprintModal {
  t: any;
  showSmallprintModal: boolean;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  smallprintModalText: string;
  smallprintModalImageSrc: string;
}

const SmallprintModal: React.FC<ISmallprintModal> = ({
  t,
  showSmallprintModal,
  setShowSmallprintModal,
  smallprintModalText,
  smallprintModalImageSrc
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  function handleCloseModal() {
    setShowSmallprintModal(false);
  }
  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current?.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (showSmallprintModal) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSmallprintModal]);

  return (
    <Modal
      isOpen={showSmallprintModal}
      overlayClassName="Overlay"
      className={`react-modal-success fixed z-50 outline-none w-full h-full flex justify-center top-0 left-0 bg-[#000]/90 smallprintModal ${
        showSmallprintModal && 'open'
      }`}
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      overlayElement={(props: any, contentElement: any) => (
        <div {...props} onClick={handleOutsideClick}>
          {contentElement}
        </div>
      )}
    >
      <div className="w-full h-full fixed flex justify-center align-middle cursor-pointer">
        <div className="h-full w-fit max-w-[90%] flex flex-col items-center justify-center cursor-pointer">
          <button
            className="absolute right-4 top-4 p-2 outline-none"
            type="button"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                fill="none"
                stroke="#fff"
                strokeWidth="2.1"
                x1="1"
                y1="1"
                x2="13"
                y2="13"
              />
              <line
                fill="none"
                stroke="#fff"
                strokeWidth="2.1"
                x1="13"
                y1="1"
                x2="1"
                y2="13"
              />
            </svg>
          </button>
          <Animation>
            <div ref={modalRef}>
              <img
                src={smallprintModalImageSrc}
                className="rounded-none my-4 max-h-[600px]"
                data-testid="smallprint_image"
                alt={`Explaining steps of ${smallprintModalText}`}
              />
              <h2 className="text-white text-center">{smallprintModalText}</h2>
            </div>
          </Animation>
        </div>
      </div>
    </Modal>
  );
};

export default SmallprintModal;
