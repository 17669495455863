import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const TabNav: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="grid grid-cols-2">
      <NavLink
        to="/"
        className={({ isActive }): string =>
          `btn btn-lg col-span-1 text-3xl btn-xl btn-ghost border-none no-animation rounded-none ${
            isActive && 'btn-disabled !bg-transparent'
          } ${!isActive && 'bg-[#e6e6e6] shadow-inner'}`
        }
      >
        <h3>{t('nav.cloud_tab')}</h3>
      </NavLink>
      <NavLink
        to="/apollo"
        className={({ isActive }): string =>
          `btn btn-lg col-span-1 text-3xl btn-xl btn-ghost border-none no-animation rounded-none ${
            isActive && 'btn-disabled !bg-transparent'
          } ${!isActive && 'bg-[#e6e6e6] shadow-inner'}`
        }
      >
        <h3>{t('nav.apollo_tab')}</h3>
      </NavLink>
    </div>
  );
};

export default TabNav;
