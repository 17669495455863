import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StarterForm from './starterForm';
import ErrorModal from '../errorModal';
import SuccessModalApollo from './successModalApollo';

interface IActivateApolloPage {
  currentLanguage: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
}

function ActivateApolloPage({
  currentLanguage,
  setLanguage,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc
}: IActivateApolloPage) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessModalApollo, setShowSuccessModalApollo] =
    useState<boolean>(false);

  //Error modal
  const [errorModalIsOpen, setErrorModalOpen] = useState<boolean>(false);
  const errorMessageHeading = t('errors.heading');
  const [errorMessageBody, setErrorMessageBody] = useState<string>('');

  //State starter form
  const [apolloId, setApolloId] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  // set page title
  useEffect(() => {
    document.title = t('form.title_apollo');
  }, [currentLanguage, t]);

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      let res = await fetch('/api/v1/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          language: currentLanguage,
          apolloId: apolloId,
          cloudEndpoint: url
        })
      });

      setLoading(false);
      if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 304) {
          setErrorMessageBody(t('errors.url_already_in_use'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else if (res.status === 404) {
          setErrorMessageBody(t('errors.apollo_id'));
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        setShowSuccessModalApollo(true);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  return (
    <>
      <StarterForm
        setUrl={setUrl}
        url={url}
        currentLanguage={currentLanguage}
        handleFormSubmit={handleFormSubmit}
        loading={loading}
        apolloId={apolloId}
        setApolloId={setApolloId}
        setShowSmallprintModal={setShowSmallprintModal}
        setSmallprintModalImageSrc={setSmallprintModalImageSrc}
        setSmallprintModalText={setSmallprintModalText}
      />
      <ErrorModal
        errorModalIsOpen={errorModalIsOpen}
        setErrorModalOpen={setErrorModalOpen}
        t={t}
        errorMessageBody={errorMessageBody}
        errorMessageHeading={errorMessageHeading}
      />
      <SuccessModalApollo
        t={t}
        showSuccessModalApollo={showSuccessModalApollo}
        setShowSuccessModalApollo={setShowSuccessModalApollo}
      />
    </>
  );
}

export default ActivateApolloPage;
