import React, { useEffect } from 'react';
import ApolloForm from './apolloForm';
import ContactDataForm from './contactDataForm';
import { IApolloBody, ICumulocityBody } from '../App';

interface IFormLogic {
  loading: boolean;
  type: string | null;
  setSalutation: React.Dispatch<React.SetStateAction<string>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCompany: React.Dispatch<React.SetStateAction<string>>;
  setIndustry: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  setPostcode: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordConfirm: React.Dispatch<React.SetStateAction<string>>;
  setAcceptToc: React.Dispatch<React.SetStateAction<boolean>>;
  setAcceptNewsletter: React.Dispatch<React.SetStateAction<boolean>>;
  acceptToc: boolean;
  acceptNewsletter: boolean;
  setErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string | null>>;
  setErrorMessageBody: React.Dispatch<React.SetStateAction<string>>;
  t: any;
  setImeiCheckSuccessfull: React.Dispatch<React.SetStateAction<boolean>>;
  setApolloId: React.Dispatch<React.SetStateAction<string>>;
  handleFormSubmit: (body: IApolloBody | ICumulocityBody) => Promise<void>;
  apolloId: string;
  email: string;
  password: string;
  passwordConfirm: string;
  salutation: string;
  firstName: string;
  lastName: string;
  company: string;
  industry: string;
  phone: string;
  street: string;
  postcode: string;
  city: string;
  currentLanguage: string;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  imei: string;
  customerId: string;
  handleNewsletterSubscription: () => Promise<void>;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
  customerIdValidated: boolean;
  imeiValidated: boolean;
  handleUrlCheck: (url: string) => Promise<boolean>;
}

const FormLogic: React.FC<IFormLogic> = ({
  loading,
  type,
  setSalutation,
  setFirstName,
  setLastName,
  setEmail,
  setCompany,
  setIndustry,
  setPhone,
  setStreet,
  setPostcode,
  setCity,
  setPassword,
  setPasswordConfirm,
  setAcceptToc,
  setAcceptNewsletter,
  acceptToc,
  acceptNewsletter,
  setErrorModalOpen,
  setErrorMessageBody,
  setType,
  setImeiCheckSuccessfull,
  setApolloId,
  t,
  handleFormSubmit,
  apolloId,
  email,
  password,
  passwordConfirm,
  salutation,
  firstName,
  lastName,
  company,
  industry,
  phone,
  street,
  postcode,
  city,
  currentLanguage,
  url,
  setUrl,
  imei,
  customerId,
  handleNewsletterSubscription,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc,
  customerIdValidated,
  imeiValidated,
  handleUrlCheck
}) => {
  useEffect(() => {
    if (type === null) {
      setImeiCheckSuccessfull(false);
      setType('initial');
      setErrorMessageBody(t('errors.imei'));
      setErrorModalOpen(true);
    } else if (type !== 'initial') {
      setImeiCheckSuccessfull(true);
    }
  }, [
    type,
    setErrorMessageBody,
    setErrorModalOpen,
    setImeiCheckSuccessfull,
    setType,
    t
  ]);

  if (type !== 'initial') {
    if (type === 'apollo') {
      return (
        <ApolloForm
          domain={url}
          currentLanguage={currentLanguage}
          imei={imei}
          customerId={customerId}
          t={t}
          apolloId={apolloId}
          setApolloId={setApolloId}
          loading={loading}
          handleFormSubmit={handleFormSubmit}
          imeiValidated={imeiValidated}
          customerIdValidated={customerIdValidated}
          setShowSmallprintModal={setShowSmallprintModal}
          setSmallprintModalImageSrc={setSmallprintModalImageSrc}
          setSmallprintModalText={setSmallprintModalText}
        />
      );
    } else if (type === 'cumulocity') {
      return (
        <ContactDataForm
          handleNewsletterSubscription={handleNewsletterSubscription}
          imei={imei}
          customerId={customerId}
          email={email}
          password={password}
          passwordConfirm={passwordConfirm}
          setSalutation={setSalutation}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
          setCompany={setCompany}
          setIndustry={setIndustry}
          setPhone={setPhone}
          setStreet={setStreet}
          setPostcode={setPostcode}
          setCity={setCity}
          setPassword={setPassword}
          setPasswordConfirm={setPasswordConfirm}
          setAcceptToc={setAcceptToc}
          setAcceptNewsletter={setAcceptNewsletter}
          acceptToc={acceptToc}
          acceptNewsletter={acceptNewsletter}
          handleFormSubmit={handleFormSubmit}
          loading={loading}
          salutation={salutation}
          firstName={firstName}
          lastName={lastName}
          company={company}
          industry={industry}
          phone={phone}
          street={street}
          postcode={postcode}
          city={city}
          currentLanguage={currentLanguage}
          url={url}
          setUrl={setUrl}
          setShowSmallprintModal={setShowSmallprintModal}
          setSmallprintModalImageSrc={setSmallprintModalImageSrc}
          setSmallprintModalText={setSmallprintModalText}
          imeiValidated={imeiValidated}
          customerIdValidated={customerIdValidated}
          handleUrlCheck={handleUrlCheck}
        />
      );
    } else if (type === null) {
      return <div></div>;
    }
  }
  return <div></div>;
};

export default FormLogic;
