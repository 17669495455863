import React from 'react';
import Animation from './animations';

interface IStep {
  title: string;
  subtitle: string;
  text: string;
  img: string;
}

const Step: React.FC<IStep> = ({ title, subtitle, text, img }) => {
  return (
    <Animation className="col-span-12 lg:col-span-4 flex justify-center">
      <div className="card relative rounded-none backdrop-blur-md bg-white/30 flex justify-center items-center shadow-xl p-4 w-[300px] h-[300px] my-12">
        <h1 className="-mt-12 bg-primary rounded-full w-16 h-16 p-0 shadow-xl absolute top-4">
          {title}
        </h1>
        <img src={img} alt="" className="object-contain h-64 m-0" />
        <h2 className="py-4">
          <b>{subtitle}</b>
        </h2>
        <p className="" dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
    </Animation>
  );
};

export default Step;
