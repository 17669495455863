import { t } from 'i18next';
import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Animation from './animations';

interface IImprintModal {
  imprintIsOpen: boolean;
  setImprintOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
}

const ImprintModal: React.FC<IImprintModal> = ({
  imprintIsOpen,
  setImprintOpen
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  function handleCloseModal() {
    setImprintOpen(false);
  }
  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current?.contains(event.target)) {
      handleCloseModal();
    }
  };
  useEffect(() => {
    if (imprintIsOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [imprintIsOpen]);
  return (
    <Modal
      isOpen={imprintIsOpen}
      overlayClassName="Overlay"
      className="react-modal fixed w-full h-full flex items-center z-50 top-0 left-0 bg-[#354452]/60 outline-none"
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      overlayElement={(props: any, contentElement: any) => (
        <div {...props} onClick={handleOutsideClick}>
          {contentElement}
        </div>
      )}
    >
      <Animation className="flex justify-center items-center">
        <div className="w-[420px] max-w-[90%] bg-white" ref={modalRef}>
          <div className="uk-modal-header rounded-none bg-[#D9F2FF]">
            <h2 className="uk-modal-title">
              {t('footer.site_notice.title') as string}
            </h2>
          </div>
          <div
            className="uk-modal-body react-modalcontent rounded-none"
            uk-overflow-auto="true"
            dangerouslySetInnerHTML={{ __html: t('imprint') }}
          ></div>
          <div className="uk-modal-body pt-0 w-full flex justify-end">
            <button
              className="btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase m-0"
              onClick={handleCloseModal}
            >
              {t('success_close') as string}
            </button>
          </div>
        </div>
      </Animation>
    </Modal>
  );
};

export default ImprintModal;
