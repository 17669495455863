import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../loadingSpinner';
import FormInput from '../formInput';
import { useState } from 'react';
import { validateApolloUrl, validateApolloId } from '../../utils/validation';
import { Link } from 'react-router-dom';
import TabNav from '../ui/tabNav';

interface IStarterForm {
  currentLanguage: string;
  apolloId: string;
  setApolloId: React.Dispatch<React.SetStateAction<string>>;
  handleFormSubmit: any;
  loading: boolean;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
}

const StarterForm: React.FC<IStarterForm> = ({
  currentLanguage,
  handleFormSubmit,
  loading,
  apolloId,
  setApolloId,
  url,
  setUrl,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc
}) => {
  //Form Validation State
  const [apolloIdValidated, setApolloIdValidated] = useState<boolean>(true);
  const [apolloIdEmpty, setApolloIdEmpty] = useState<boolean>(false);
  const [domainValidated, setDomainValidated] = useState<boolean>(true);
  const [domainEmpty, setDomainEmpty] = useState<boolean>(false);
  const [starterFormValidated, setStarterFormValidated] =
    useState<boolean>(true);
  const [formFirstRender, setFormFirstRender] = useState<boolean>(true);

  const { t } = useTranslation();

  // const handleFormValidation = () => {
  //   setFormFirstRender(false);
  //   let formValid = false;
  //   let urlValid = false;
  //   let apolloIdValid = false;

  //   if (url && apolloId) {
  //     formValid = true;
  //     setStarterFormValidated(true);

  //     //Check if inputs are valid
  //     urlValid = validateApolloUrl(url).validated;
  //     setDomainValidated(urlValid);

  //     apolloIdValid = validateApolloId(apolloId).validated;
  //     setApolloIdValidated(apolloIdValid);
  //   } else {
  //     formValid = false;
  //     setStarterFormValidated(false);
  //     if (!url) {
  //       setDomainEmpty(true);
  //     }
  //     if (!apolloId) {
  //       setApolloIdEmpty(true);
  //     }
  //   }

  //   if (!urlValid || !apolloIdValid) {
  //     formValid = false;
  //     setStarterFormValidated(false);
  //   } else {
  //     formValid = true;
  //     setStarterFormValidated(true);
  //   }
  //   if (!formValid) return false;
  //   else return true;
  // };

  // useEffect(() => {
  //   if (!formFirstRender) handleFormValidation();
  // }, [url, apolloId, formFirstRender, handleFormValidation]);

  const handleFormValidation = useCallback(() => {
    setFormFirstRender(false);
    let formValid = false;
    let urlValid = false;
    let apolloIdValid = false;

    if (url && apolloId) {
      formValid = true;
      setStarterFormValidated(true);

      // Check if inputs are valid
      urlValid = validateApolloUrl(url).validated;
      setDomainValidated(urlValid);

      apolloIdValid = validateApolloId(apolloId).validated;
      setApolloIdValidated(apolloIdValid);
    } else {
      formValid = false;
      setStarterFormValidated(false);
      if (!url) {
        setDomainEmpty(true);
      }
      if (!apolloId) {
        setApolloIdEmpty(true);
      }
    }

    if (!urlValid || !apolloIdValid) {
      formValid = false;
      setStarterFormValidated(false);
    } else {
      formValid = true;
      setStarterFormValidated(true);
    }

    if (!formValid) return false;
    else return true;
  }, [url, apolloId]);

  const handleFormSubmission = (e: any) => {
    e.preventDefault();
    const formValid = handleFormValidation();
    if (formValid) handleFormSubmit();
  };

  useEffect(() => {
    if (!formFirstRender) handleFormValidation();
  }, [url, apolloId, formFirstRender, handleFormValidation]);

  return (
    <>
      <div className="w-full flex justify-center items-center bg-[#D9F2FF] h-[68px] p-4">
        <h1 className="text-[2rem] leading-10">{t('form.title_apollo')}</h1>
      </div>

      <TabNav />

      <form id="registration" className="p-6">
        <div
          role="alert"
          className="alert border-none bg-[#e6e6e6] relative right-0 text-[14px] rounded-none flex justify-between mt-4"
        >
          <span className="text-neutral">
            {t('apollo.signup.signup_request')}
          </span>
          <div className="float-right">
            <Link
              to="/apollo/register"
              className="btn btn-lg btn-primary focus:bg-primary text-xl text-white rounded-none"
              data-testid="signup_button"
            >
              {t('apollo.signup.signup_prompt')}
            </Link>
          </div>
        </div>

        <div className="mb-8 text-left">
          <label htmlFor="form-apolloId">{t('apollo.subheading')}</label>
          <div className="grid grid-cols-12 join join-horizontal">
            <FormInput
              setEmpty={setApolloIdEmpty}
              isEmpty={apolloIdEmpty}
              input={apolloId}
              isValidated={apolloIdValidated}
              setValidated={setApolloIdValidated}
              validationFct={validateApolloId}
              id="form-apolloId"
              name="apolloId"
              type="text"
              className="
            uk-input
            as-input
            uk-form-large
            uk-text-center
            uk-border-rounded
            uk-background-muted
            w-full
            text-[14px]
            join-item
            "
              wrapperClassName="w-full col-span-11"
              placeholder={t('apollo.placeholder')}
              autoComplete="off"
              required
              minLength={36}
              maxLength={36}
              onChange={(e: any) => setApolloId(e.currentTarget.value)}
            />
            <div
              className=" uk-input uk-form-large uk-border-rounded uk-background-muted 
              col-span-1 join-item cursor-pointer flex items-center justify-center
              select-none text-gray-500 text-[18px]"
              data-testid="activate_apollo_smallprint"
              onClick={() => {
                // setShowSmallprintModal(true);
                // setSmallprintModalText(t('apollo.smallprint'));
                // setSmallprintModalImageSrc(
                //   '/img/lang/' + currentLanguage + '/imei.jpg'
                // ); // image needs to be changed (LD)
              }}
            >
              &#63;
            </div>
          </div>
        </div>

        <div className="my-8 text-left">
          <label htmlFor="form-customer-id">{t('apollo.form.url.label')}</label>
          <FormInput
            setEmpty={setDomainEmpty}
            isEmpty={domainEmpty}
            isValidated={domainValidated}
            setValidated={setDomainValidated}
            validationFct={validateApolloUrl}
            input={url}
            id="form-domain"
            name="domain"
            type="text"
            className="
              uk-input
              as-input
              uk-form-large
              uk-text-center
              uk-border-rounded
              uk-background-muted
              w-full
              text-[14px]
              "
            wrapperClassName="w-full"
            placeholder={t('apollo.form.url.placeholder')}
            autoComplete="off"
            required
            minLength={3}
            maxLength={50}
            onChange={(e: any) => setUrl(e.currentTarget.value)}
          />
        </div>

        <div className="flex flex-col justify-center items-start width-full">
          {starterFormValidated ? (
            <div></div>
          ) : (
            <label className="uk-text-danger mb-4" style={{ fontSize: '90%' }}>
              {t('apollo.errors.general')}
            </label>
          )}
          <button
            type="submit"
            className="btn p-4 btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase"
            onClick={(e) => handleFormSubmission(e)}
          >
            {loading && <LoadingSpinner />}
            <p className="text-2xl">{t('apollo.apollo.button')}</p>
          </button>
        </div>
      </form>
    </>
  );
};

export default StarterForm;
