import { useEffect } from 'react';
import { motion, useAnimation, Variants } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface AnimationProps {
  opacity?: number;
  duration?: number;
  dir?: 'up' | 'down' | 'right' | 'left' | 'scaleIn' | 'blurIn';
  style?: React.CSSProperties;
  whileTap?: boolean;
  children: React.ReactNode;
  className?: string;
}

function Animation(props: AnimationProps) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.25
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  let variant: Variants | null = null;
  const opacity = props.opacity ?? 0;
  const duration = props.duration ?? 0.3;
  switch (props.dir) {
    case 'up':
      variant = {
        hidden: { y: 20, opacity },
        visible: { y: 0, opacity: 1 }
      };
      break;

    case 'down':
      variant = {
        hidden: { y: -20, opacity },
        visible: { y: 0, opacity: 1 }
      };
      break;

    case 'right':
      variant = {
        hidden: { x: -20, opacity },
        visible: { x: 0, opacity: 1 }
      };
      break;

    case 'left':
      variant = {
        hidden: { x: 20, opacity },
        visible: { x: 0, opacity: 1 }
      };
      break;

    case 'scaleIn':
      variant = {
        hidden: { scale: 0.9, opacity },
        visible: { scale: 1, opacity: 1 }
      };
      break;

    case 'blurIn':
      variant = {
        hidden: { opacity },
        visible: { opacity: 1 }
      };
      break;

    default:
      variant = {
        hidden: { y: 20, opacity },
        visible: { y: 0, opacity: 1 }
      };
      break;
  }

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration }}
      variants={variant}
      style={{
        width: '100%',
        ...props.style
      }}
      whileTap={props.whileTap === true ? { scale: 0.94 } : undefined}
      className={props.className}
    >
      {props.children}
    </motion.div>
  );
}

export default Animation;
