import React, { useState } from 'react';
import i18next from 'i18next';

interface IFormInput {
  validationFct: (any: any) => {
    validated: boolean;
    message: string;
  };
  rest?: any;
  input: any;
  props?: any;
  id?: string;
  name?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  pattern?: string;
  maxLength?: any;
  onChange?: any;
  onBlur?: any;
  isValidated: boolean;
  setValidated: React.Dispatch<React.SetStateAction<boolean>>;
  isEmpty?: boolean;
  setEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: any;
  minLength?: any;
  style?: any;
  passwordInput?: string;
  wrapperClassName?: string;
  message?: string;
}

const FormInput: React.FC<IFormInput> & React.HTMLAttributes<any> = ({
  validationFct,
  input,
  id,
  name,
  type,
  className,
  placeholder,
  autoComplete,
  required,
  pattern,
  maxLength,
  onChange,
  onBlur,
  isValidated,
  setValidated,
  minLength,
  isEmpty,
  setEmpty,
  style,
  passwordInput,
  wrapperClassName,
  message,
  ...props
}) => {
  const [errMessage, setErrMessage] = useState<string>();
  const reqMessage = i18next.t('validation.required');

  return (
    <div className={wrapperClassName}>
      <input
        onBlur={(e) => {
          if (onBlur) onBlur();
          if (passwordInput) {
            if (setEmpty) setEmpty(false);
            setValidated(
              validationFct({ passwordConfirm: input, password: passwordInput })
                .validated
            );
            setErrMessage(
              validationFct({ passwordConfirm: input, password: passwordInput })
                .message
            );
          } else if (input === '' || !input) {
            if (setEmpty) setEmpty(true);
          } else if (input) {
            if (setEmpty) setEmpty(false);
            setValidated(validationFct(input).validated);
            setErrMessage(
              validationFct({ passwordConfirm: input, password: passwordInput })
                .message
            );
          }
        }}
        id={id}
        name={name}
        type={type}
        className={className}
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
        maxLength={maxLength}
        pattern={pattern}
        onChange={onChange}
        minLength={minLength}
        style={style}
        value={input}
      />
      <div>
        {message && (
          <div className="uk-text-danger mt-4" style={{ fontSize: '90%' }}>
            {message}
          </div>
        )}
        {isValidated ? (
          <></>
        ) : (
          <div className="uk-text-danger mt-4" style={{ fontSize: '90%' }}>
            {errMessage}
          </div>
        )}
        {isEmpty ? (
          <div className="uk-text-danger mt-4" style={{ fontSize: '90%' }}>
            {reqMessage}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default FormInput;
