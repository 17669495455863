import i18next, { t } from 'i18next';

export const validateUrl = (
  url: string
): { validated: boolean; message: string } => {
  const regex = /^[a-z]+[a-z0-9-]*[a-z0-9]+$/;

  if (!regex.test(url)) {
    return { validated: false, message: i18next.t('validation.url') };
  }

  return { validated: true, message: '' };
};

export const validatePhone = (
  phone: string
): { validated: boolean; message: string } => {
  const regex = /^$|^(\+|0{2})[\d\s/-]{1,30}$/;

  if (!regex.test(phone)) {
    return { validated: false, message: t('validation.phone') };
  }
  return { validated: true, message: '' };
};

export const validateImei = (
  imei: string
): { validated: boolean; message: string } => {
  const regex = /^[0-9]*$/;

  if (!regex.test(imei)) {
    return { validated: false, message: i18next.t('validation.imei') };
  } else if (imei.length < 15) {
    return { validated: false, message: i18next.t('validation.imeiLength') };
  }
  return { validated: true, message: '' };
};

export const validateEmail = (
  email: string
): { validated: boolean; message: string } => {
  const regex =
    /^[^<>()[\]\\,;:%#^\s@"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;

  if (!regex.test(email)) {
    return { validated: false, message: i18next.t('validation.email') };
  }
  return { validated: true, message: '' };
};

export const validatePassword = (
  password: string
): { validated: boolean; message: string } => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&`~#^()_|+\-=;:'",.<>/\\{}[\]])[A-Za-z\d@$!%*?&`~#^()_|+\-=;:'",.<>/\\{}[\]]{8,}$/;

  if (!regex.test(password)) {
    return { validated: false, message: i18next.t('validation.password') };
  }
  return { validated: true, message: '' };
};

interface IValidatePasswordConfirm {
  password: string;
  passwordConfirm: string;
}

export const validatePasswordConfirm = ({
  password,
  passwordConfirm
}: IValidatePasswordConfirm): { validated: boolean; message: string } => {
  if (password !== passwordConfirm) {
    return {
      validated: false,
      message: i18next.t('validation.passwordConfirm')
    };
  }
  return { validated: true, message: '' };
};

export const validateAcceptToc = (
  bool: boolean
): { validated: boolean; message: string } => {
  if (!bool) {
    return { validated: false, message: i18next.t('validation.required') };
  }
  return { validated: true, message: '' };
};

export const validatePostcode = (
  postcode: string
): { validated: boolean; message: string } => {
  const regex = /^[0-9]*$/;

  if (!regex.test(postcode) || postcode.length > 5) {
    return { validated: false, message: i18next.t('validation.postcode') };
  }
  return { validated: true, message: '' };
};

export const validateCustomerNr = (
  customerNr: string
): { validated: boolean; message: string } => {
  const regex = /^[0-9]*$/;

  if (!regex.test(customerNr)) {
    return { validated: false, message: i18next.t('validation.customerNr') };
  } else if (customerNr.length < 7) {
    return {
      validated: false,
      message: i18next.t('validation.customerNrLength')
    };
  }
  return { validated: true, message: '' };
};

export const validateApolloId = (
  apolloId: string
): { validated: boolean; message: string } => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

  if (!regex.test(apolloId)) {
    return { validated: false, message: i18next.t('validation.apolloId') };
  }
  return { validated: true, message: '' };
};

export const validateApolloUrl = (
  url: string
): { validated: boolean; message: string } => {
  const regex =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  if (!regex.test(url)) {
    return { validated: false, message: i18next.t('validation.url') };
  }

  return { validated: true, message: '' };
};

export const requiredField = () => {
  return { validated: false, message: i18next.t('validation.required') };
};
