import React from 'react';
import { requiredField } from '../utils/validation';

interface IRequiredInputField {
  input: any;
  props?: any;
  id?: string;
  name?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  pattern?: string;
  maxLength?: any;
  onChange?: any;
  isEmpty: boolean;
  minLength?: any;
  style?: any;
  wrapperClassName?: string;
  setEmpty: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequiredInputField: React.FC<IRequiredInputField> = ({
  input,
  type,
  minLength,
  style,
  id,
  name,
  className,
  placeholder,
  autoComplete,
  required,
  pattern,
  maxLength,
  onChange,
  isEmpty,
  wrapperClassName,
  setEmpty
}) => {
  return (
    <div className={wrapperClassName}>
      <input
        onBlur={(e) => {
          if (input) {
            setEmpty(false);
          } else if (input === '' || !input) {
            setEmpty(true);
          }
        }}
        id={id}
        name={name}
        type={type}
        className={className}
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
        maxLength={maxLength}
        pattern={pattern}
        onChange={onChange}
        minLength={minLength}
        style={style}
        value={input}
      />
      <div>
        {isEmpty ? (
          <label className="uk-text-danger mt-4" style={{ fontSize: '90%' }}>
            {requiredField().message}
          </label>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default RequiredInputField;
